import http from "../Http";
import Service from "../Service";

export default class UserService extends Service {

	all(client_id) {
		return http.get("usuario", {
			headers: this.getHeaders(),
			params: { cliente_id: client_id }
		});
    }
    
    update(client_id, id, data) {
		return http.put(`usuario/${id}`, this.parse_params(data, client_id), {
			headers: this.getHeaders()
		});
	}
	update2(client_id, id, data) {
		if (!id) {
			return http.post(`usuario`, this.parse_params2(data, client_id), {
				headers: this.getHeaders()
			});
		}
		return http.put(`usuario/${id}`, this.parse_params2(data, client_id), {
			headers: this.getHeaders()
		});
	}

	create(client_id, data) {
		return http.post(`usuario`, this.parse_params(data), {
			headers: this.getHeaders(),
			params: { cliente_id: client_id }
		});
	}

	remove(client_id, data) {
		return http.delete(`usuario/${data.usu_id}`, {
			headers: this.getHeaders(),
			params: { cliente_id: client_id }
		});
	}
    
    region_data() {
		return http.get(`usuario/region`, {
			headers: this.getHeaders()
		});
	}

	load_profiles(cli_id) {
		return http.get(`perfil`, {
			headers: this.getHeaders(),
			params: {
				cliente_id: cli_id, 
				tipo: "C"
			}
		});
	}

	parse_params(user, cli_id) {
		return {
			'cliente_id': cli_id,
			'cpf': user.usu_cpf,
			'loja': user.store ? user.store.loj_id : null,
			'login': user.usu_login,
			'perfil_id' : user.profile ? user.profile.pfl_id : null,
			'nome': user.usu_nome,
			'senha' : user.usu_senha,
			'email': user.usu_email,
			'telefone': user.usu_telefone
		}
	}
	parse_params2(user, cli_id) {
		console.log(user);
		return {
			'cliente_id': cli_id,
			'cpf': user.store ? user.store.cnpj : null,
			'loja': user.store ? user.store.id : null,
			'login': user.login,
			'perfil_id' : user.profile ? user.profile.id : null,
			'nome': user.name,
			'senha' : user.decrypted_password,
			'email': user.email,
			'telefone': user.phone,
			'usuario_externo_user': user.user_external,
			'usuario_externo_pass': user.user_external_pass,
			'usuario_externo_subdomain': user.user_external_domain,
			'token': user.token,
			'tipo': user.tipo
		}
	}
	getLog(params){
        return http.get('admin/audit', {
			headers: this.getHeaders(),
			params
		})
    }
	getLogOptions(){
        return http.get('admin/audit/selected', {
			headers: this.getHeaders()
		})
    }

}
